import api from "../services/api";

export async function LocalFindByOrigem(id_cliente, id_origem) {
  return await api
    .post("/api/local/find/origem", { id_cliente,id_origem })
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}
