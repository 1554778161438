import React, { Fragment, useEffect, useState } from "react";

import DivElement from "../../../styles/Div/Elements";
import api from "../../../services/api";

interface Action {
  isVisible: boolean;
  handleSetData: () => void;
  id_cliente: string;
};

export function ListaSetoresView({isVisible,handleSetData, id_cliente } : Action) {

  const [ ListaSetores, setListaSetores ] = useState([]);

  useEffect(() => {
    if(isVisible)
      api.post("setor/find", { id_cliente })
      .then((res) => {
        setListaSetores(res.data);
      });
  }, [ isVisible ])

  return (
    isVisible ?
      <Fragment>
        <h2>Para qual setor deseja criar a ordem de serviço ?</h2>
        <DivElement>
          {ListaSetores.map((os, index) => (
            <div key={index} onClick={() => handleSetData(os)}>
              <p>{os.desc_Setor}</p>
            </div>
          ))}
        </DivElement>
      </Fragment>
      : ''
  )
};
