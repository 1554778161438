import axios from 'axios';
const token = localStorage.getItem('token') || "";
const id_cliente = localStorage.getItem('id_cliente') || "";
const id_usuario = localStorage.getItem('id_usuario') || "";
const api = axios.create({
		baseURL: 'https://api.drleito.com.br',
    headers: {
		Authorization: token,
    id_cliente: id_cliente,
    id_usuario: id_usuario,
	 	'Content-Type': 'application/json',
     'Accept': 'application/json',
     'Access-Control-Allow-Origin': '*'
	 }
})

export default api;
