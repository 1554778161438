import { FiCheck, FiXCircle } from "react-icons/fi";
import React, { useContext, useEffect, useState } from "react";

import {ButtonClose} from "../../../styles/Icons/ClosePage";
import { Context as ContextUser } from "../../../Context/UserContext";
import DivElement from "../../../styles/Div/Elements";
import { FaSave } from "react-icons/fa";
import { FormObservacao } from "../../formObservacao";
import Modal from "react-modal";
import { ModalSmall } from "../../../styles/modal.css";
import { OrdemServicoAddItem } from "../AddItem";
import { Table } from "./styles"
import api from "../../../services/api";
import styles from "./styles.module.scss"
import { toast } from "react-toastify";

interface Action {
  isOpen: boolean;
  handleChangeModalIsOpen: () => void;
  id: string;
  id_cliente: string;
  id_usuario: string;
};

export function OrdemServicoPorID({isOpen,handleChangeModalIsOpen, id,id_cliente,id_usuario } : Action) {
  const { user } = useContext(ContextUser);
  const [ ItensOrdemServico, setItensOrdemServico ]= useState([]);
  const [ observacoesOS, setobservacoesOS ]= useState([]);
  const [ modalIsOpenAdicionarItem, setmodalIsOpenAdicionarItem ] = useState(false);
  const [ reload, setreload ] = useState(false);

  useEffect(() => {
    if(isOpen)
      api.post("ordem/it/find/ordem", {
        id_ordem: id , id_cliente
      })
      .then((res) => {
        setItensOrdemServico(res.data);
      });

      api.post("ordem/find/obs", {
        id_ordem_servico: id,id_cliente
      }).then((res) => {
        setobservacoesOS(res.data);
      })
  }, [ isOpen, modalIsOpenAdicionarItem, reload ])

  const handleChangeModalIsOpenAdicionarItem = () => {
    setmodalIsOpenAdicionarItem(!modalIsOpenAdicionarItem);
  };

  const handleFeito = (data) => {
    api.post("ordem/it/update/id", {
      "observacao_sn_feito": "Feito",
      "sn_feito": "S",
      "usuario_feito": user.nm_usuario,
      "id_it_ordem": data.id,
      "id_cliente": user.id_cliente
    }).then((res) => {
      toast.success('Confirmado com sucesso...');
      handleChangeModalIsOpen();
    })
  };

  const FinalizarOS = () => {
    api.post("ordem/update/status/concluido", {
      id,
	    "data_conclusao": "2021-07-26",
	    "id_cliente": id_cliente
    }).then(() => {
      toast.success('O.S finalizada com sucesso...');
      handleChangeModalIsOpen();
    })
  };

  return (
    <Modal transparent isOpen={isOpen} style={ModalSmall}>
      <ButtonClose action={handleChangeModalIsOpen} />
      <h1>Ordem de serviço</h1>

      {localStorage.getItem('id_setor') == '35ec2d68-bcfb-4e99-b2b7-5ad32b10e33e' ?
        <button onClick={() => FinalizarOS()}>Finalizar O.S</button>
      : ''}

      <DivElement>
        {ItensOrdemServico.map((os, index) => (
          <div key={index} >
            {os.sn_feito=== "S" ?  <FiCheck color="green" size="32"/> : <FiXCircle color="red" size="32" title="Pendente"/> }
            <p>{os.desc_item_verificacao}</p>
            <p>Criado por: {os.nm_usuario}</p>
            <p><b>Motivo:</b> {os.observacao_it_ordem}</p>
            <p><b>Observação Manutenção:</b> {os.observacao_sn_feito}</p>

            {os.sn_feito=== "N" ?  <button id="button-primary" onClick={() => handleFeito(os)}>Marcar como feito</button> : '' }

          </div>
        ))}
      </DivElement>
        {observacoesOS.length > 0 ?
          <Table>
            <thead>
              <th>Data</th>
              <th>Usuário</th>
              <th>desc_observacao</th>
            </thead>
            {observacoesOS.map((observacao) => (
              <tr>
              <td>{observacao.data}</td>
              <td>{observacao.nm_usuario}</td>
              <td>{observacao.desc_observacao}</td>
            </tr>
            ))}
          </Table> :  ''
        }

        <FormObservacao
          id_cliente={user.id_cliente}
          nm_usuario={user.nm_usuario}
          id={id}
          handleReload={() => setreload(!reload)}
        />

      <OrdemServicoAddItem
        isOpen={modalIsOpenAdicionarItem}
        id_cliente={id_cliente}
        id_usuario={id_usuario}
        id_ordem={id}
        handleChangeModalIsOpen={handleChangeModalIsOpenAdicionarItem}
      />
    </Modal>
  )
};
