import { FiHome, FiLogOut, FiPlusCircle, FiUsers } from "react-icons/fi";
import React, { useContext, useState } from "react";

import { ButtonNovaOrdemServico } from "../buttonNovaOrdemServico";
import { CgUserlane } from "react-icons/cg";
import { Context as ContextUser } from "../../Context/UserContext";
import { CriarOrdemServico } from "../OrdemServico/Criar";
import { ListaCheckList } from "../CheckList/Lista";
import { OrdemServicoPorStatus } from "../OrdemServico/PorStatus";
import styles from "./styles.module.scss";

export function HeaderDefault() {
  const { user } = useContext(ContextUser);
  const [modalisOpenOrdemServico, setmodalisOpenOrdemServico] = useState(false);
  const [modalIsOpenCheckList, setmodalIsOpenCheckList] = useState(false);
  const [modalIsOpenCriarOrdemServico, setmodalIsOpenCriarOrdemServico] =
    useState(false);

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  const handleLogin = () => {
    window.location.href = "/login";
  };

  const handleModalChangeModalOrcamentos = () => {
    setmodalisOpenOrdemServico(!modalisOpenOrdemServico);
  };

  const handleModalChangeModalCheckList = () => {
    setmodalIsOpenCheckList(!modalIsOpenCheckList);
  };

  const handleChangeModalOrdemServico = () => {
    setmodalIsOpenCriarOrdemServico(!modalIsOpenCriarOrdemServico);
  };

  const handleRedirect = (data) => {
    window.location.href = "/" + data
  }

  if (user.id) {
    return (
      <header className={styles.header}>
        <div className={styles.infoUser}>
          <CgUserlane size="24" />
          <h2>{user.nm_usuario} </h2>
          <br />
          <p className={styles.build}>v.2021-10.15</p>
        </div>
        <div className={styles.infoUser}>
          <FiHome
            onClick={() => handleRedirect("dashboard")}
            size="32"
            className={styles.svg}
            title="Voltar para o início"
          />
          <FiPlusCircle
            onClick={() => handleChangeModalOrdemServico()}
            size="32"
            color="F9004B"
            className={styles.svg}
            title="Criar ordem de serviço"
          />

          <FiLogOut
            onClick={() => handleLogout()}
            title="Sair"
            size="24"
            className={styles.svg}
          />

          <OrdemServicoPorStatus
            isOpen={modalisOpenOrdemServico}
            handleChangeModalIsOpen={handleModalChangeModalOrcamentos}
            id_cliente={user.id_cliente}
            id_usuario={user.id}
          />

          <ListaCheckList
            isOpen={modalIsOpenCheckList}
            handleChangeModalIsOpen={handleModalChangeModalCheckList}
            id_cliente={user.id_cliente}
          />

          <CriarOrdemServico
            isOpen={modalIsOpenCriarOrdemServico}
            handleChangeModalIsOpen={handleChangeModalOrdemServico}
            id_cliente={user.id_cliente}
          />
        </div>
      </header>
    );
  } else {
    return (
      <header className={styles.header}>
        <a onClick={() => handleLogin()}>
          <FiUsers size="14" /> Login
        </a>
      </header>
    );
  }
}
