import styled from "styled-components";

export const OpcoesCriarOS = styled.div`
  margin-top: 2rem;
  display: flex;
  //justify-content: space-around !important;

  align-items: center;
  text-align: center;
  min-height: 5rem;

  @media(max-width: 800px) {
    &{
      flex-direction: column;
    }
    & h3{
      text-align: left !important;
    }
    & a{
      width: 100% !important;
    }
  }

  a {
    margin: 8px;
    padding: 32px;
    min-width: 4rem;
    border-radius: 4px;
    transition: filter 0.2s;
    color: var(--white);
    &:hover {
      filter: brightness(90%);
    }
  }
`;

export const LinkConfirmacao = styled.a`
  background-color: var(--green);
`
export const LinkCancelar = styled.a`
  background-color: var(--red);
`
