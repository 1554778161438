import "./global.css";
import "react-toastify/dist/ReactToastify.min.css";

import React, { Fragment } from "react";

import Main from "./styles/Main";
import Routes from "./routes";
import { ToastContainer } from "react-toastify";
import { UserProvider } from "./Context/UserContext";

function App() {
  return (
    <UserProvider >
    <Main>
      <Fragment>
        <ToastContainer />
        <Routes />
      </Fragment>
    </Main>
    </UserProvider>
  );
}

export default App;
