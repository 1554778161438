import { LinkConfirmacao, OpcoesCriarOS } from "./styles";
import React, { Fragment, useEffect, useState } from "react";

import {ButtonClose} from "../../../styles/Icons/ClosePage";
import DivElement from "../../../styles/Div/Elements";
import { ListaLocalPorOrigem } from "../../Local/ListaLocal";
import { ListaOrigem } from "../../Origem/ListaOrigem";
import { ListaSetoresView } from "../../Setores/Lista";
import Modal from "react-modal";
import { ModalSmall } from "../../../styles/modal.css";
import api from "../../../services/api";
import styles from "./styles.module.scss";
import { toast } from "react-toastify";

interface Action {
  isOpen: boolean;
  handleChangeModalIsOpen: () => void;
  id_cliente: string;
};

export function CriarOrdemServico({isOpen,handleChangeModalIsOpen, id_cliente } : Action) {

  const [ OrigemSelecionada, setOrigemSelecionada ] = useState([]);
  const [ observacao, setobservacao ] = useState("");
  const [ localSelecionado, setlocalSelecionado ] = useState([]);
  const [ setorSelecionado, setsetorSelecionado ] = useState([]);
  const [ itemSelecionado, setitemSelecionado ] = useState([]);
  const [ listaItensNoLocal, setlistaItensNoLocal ] = useState([]);
  const [ ListaOrigemSelecionaVisible, setListaOrigemSelecionaVisible ] = useState(true);
  const [ ListaOrigemLocalVisible, setListaOrigemLocalVisible ] = useState(false);
  const [ ListaSetorVisible, setListaSetorVisible ] = useState(false);
  const [ isVisibleListaItensPorSetor, setisVisibleListaItensPorSetor ] = useState(false);
  const [ isVisibleCriarOS, setisVisibleCriarOS ] = useState(false);
  const [ isVisibleButtonSalvar, setisVisibleButtonSalvar ] = useState(false);
  const [ isVisibleListaItensPorLocal, setisVisibleListaItensPorLocal ] = useState(false);

  const handleSetOrigemSelecionada = (data) => {
    setOrigemSelecionada(data);
    setListaOrigemSelecionaVisible(false);
    setListaOrigemLocalVisible(true);
  };

  const handleSetLocalSelecionado = (data) => {
    setlocalSelecionado(data);
    setListaOrigemLocalVisible(false);
    setListaSetorVisible(true);
    setListaOrigemLocalVisible(false);
  };

  const handleAlterDefault = () => {
    setListaOrigemSelecionaVisible(true);
    setListaOrigemLocalVisible(false);
    handleChangeModalIsOpen();
    setOrigemSelecionada([]);
    setlocalSelecionado([]);
    setsetorSelecionado([]);
    setisVisibleListaItensPorLocal(false);
  };

  const handleSetSetorSelecionado = (data) => {
    setsetorSelecionado(data);
    setListaSetorVisible(false);
    setisVisibleListaItensPorSetor(true);
    setisVisibleButtonSalvar(true);
    setisVisibleListaItensPorLocal(true);
  };

  const handleCriarOS = () => {
    const id_usuario = localStorage.getItem('id_usuario');
    observacao.length > 1 ?
      api.post("ordem/add", {
        id_cliente, id_setor: setorSelecionado.id, id_usuario, id_origem: OrigemSelecionada.id, id_local: localSelecionado.id, observacao
      }).then((res) => {
        let id_ordem = res.data[0].id;

        api.post("ordem/it/add", {
          id_cliente, id_usuario, id_ordem, id_item_verificacao: itemSelecionado.id_item, observacao_it_ordem: observacao
        }).then(() => {
          toast.success('Ordem de serviço criada com sucesso', {
            autoClose: 1500
          })
          handleChangeModalIsOpen();
        }).catch(() => {
          toast.error('Erro...')
        })
      })

    : toast.info('Digite uma observação')
  };

  const handleSetItemSelecionado = (data) => {
    if(data) {
      toast.success(data.desc_item_verificacao +' selecionado com sucesso...');
      setitemSelecionado(data);
      setisVisibleButtonSalvar(true);
    }
  };

  useEffect(() => {
    if(isOpen) {
      setisVisibleButtonSalvar(false);
      setisVisibleCriarOS(true);
      api.post("api/item/verificacao/find/local", {
        "id_local": localSelecionado.id,
        "id_cliente": localStorage.getItem('id_cliente'),
      }).then((res) => {
        setlistaItensNoLocal(res.data);
      })
    }
  }, [ isVisibleListaItensPorSetor, isOpen])

  return (
    <Modal transparent isOpen={isOpen} style={ModalSmall}>
      <ButtonClose action={handleAlterDefault} />
      <h1>Criar ordem de serviço</h1>

      <ListaOrigem
        id_cliente={id_cliente}
        isVisible={ListaOrigemSelecionaVisible}
        handleSetData={handleSetOrigemSelecionada}
      />
      <ListaLocalPorOrigem
        id_cliente={id_cliente}
        id_origem={OrigemSelecionada.id}
        isVisible={ListaOrigemLocalVisible}
        handleSetData={handleSetLocalSelecionado}
      />

      <ListaSetoresView
        id_cliente={id_cliente}
        isVisible={ListaSetorVisible}
        handleSetData={handleSetSetorSelecionado}
      />

      {isVisibleListaItensPorLocal ?
        <Fragment>
          <h2>Para qual item deseja criar a O.S?</h2>
          <DivElement>
            {listaItensNoLocal.map((os, index) => (
              <div key={index} onClick={() => handleSetItemSelecionado(os)}>
                <p>{os.desc_item_verificacao}</p>
              </div>
            ))}
          </DivElement>
        </Fragment>
      : ''}

      {isVisibleButtonSalvar ?
        <Fragment>
          <h2>Qual o motivo ?</h2>
          <form className={styles.formObservacao}>
            <input
              type="text"
              placeholder="Digite aqui o motivo da O.S..."
              onChange={(e) => setobservacao(e.target.value)}
            />
          </form>
          <OpcoesCriarOS>
            <LinkConfirmacao onClick={() => handleCriarOS() }>Criar ordem de serviço...</LinkConfirmacao>
          </OpcoesCriarOS>
        </Fragment>
      :  ''}
    </Modal>
  )
};
