import { Container } from "./styles";
import React from "react";

export default class Post extends React.Component {
  render () {
    return (
      <Container>
        {this.props.children}
      </Container>
    )
  }
}
