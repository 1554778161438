import { DivButton } from "./styles";
import { FiX } from "react-icons/fi";
import React from "react";
interface Action {
  action: () => void;
};

export function ButtonClose({ action} : Action) {
  return (
    <DivButton>
      <FiX color="red" size={32} onClick={action}/>
    </DivButton>
  )
};
