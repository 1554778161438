import styled from "styled-components";

export const DivButton = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100% !important;

  svg:hover {
    cursor: pointer
  }
`;
