import React, { Fragment, useEffect, useState } from "react";

import DivElement from "../../../styles/Div/Elements";
import api from "../../../services/api";

interface Action {
  isVisible: boolean;
  handleSetData: () => void;
  id_cliente: string;
  id_origem: string;
};

export function ListaLocalPorOrigem({isVisible,handleSetData, id_cliente, id_origem } : Action) {

  const [ ListaLocal, setListaLocal ] = useState([]);

  useEffect(() => {
    if(isVisible)
      api.post("api/local/find/origem", { id_cliente, id_origem })
      .then((res) => {
        setListaLocal(res.data);
      });
  }, [ isVisible ])

  return (
    isVisible ?
      <Fragment>
        <h3>Selecione o local...</h3>
        <DivElement>
          {ListaLocal.map((os, index) => (
            <div key={index} onClick={() => handleSetData(os)}>
              <p>{os.desc_local}</p>
            </div>
          ))}
        </DivElement>
      </Fragment>
      : ''
  )
};
