import styled from "styled-components";

export const DivSeparator = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const Table = styled.table`
  text-align: left;
  width: 100%;
  thead {
    border-radius: 4px;
    padding: 8px;
    text-align: left;
    background-color: var(--gray) !important;
    color: var(--white);

    th {
      border-radius: 4px;
      padding: 8px;
    }
  }

  tr td {
    padding: 8px;
  }
`;
