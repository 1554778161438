import React, { useState } from "react";

import { FaSave } from "react-icons/fa";
import api from "../../services/api";
import styles from "./styles.module.scss"

interface info {
  handleReload: () => void;
  id_cliente: String;
  nm_usuario: String;
  id: String;
}

export function FormObservacao({ handleReload,id_cliente,nm_usuario,id } : info ){
  const [ NovaObservacao, setNovaObservacao ]= useState("");

  async function handleAddObservacao(e) {
    e.preventDefault();
    api.post("ordem/add/observacao", {
      id_cliente,
      "id_ordem_servico": id,
      nm_usuario,
      "desc_observacao": NovaObservacao,
      "desc_local": localStorage.getItem('desc_local'),
    }).then(() => {
      handleReload();
      setNovaObservacao("");
    })
  };

  return (
    <form onSubmit={handleAddObservacao} className={styles.formObservacao}>
      <input
        type="text"
        value={NovaObservacao}
        onChange={(e) => setNovaObservacao(e.target.value)}
        placeholder="Para acrescentar uma observação, digite aqui e clique em salvar..."
      />
      <button type="submit" title="Salvar Observação"> <FaSave size="32" color="29292e" /></button>
    </form>
  )
};
