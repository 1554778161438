import api from "../services/api";

export async function CreateItemVerificacao(id_cliente, desc_item_verificacao) {
  return await api
    .post("api/item/verificacao/create", {
      id_cliente: localStorage.getItem("id_cliente"),
      desc_item_verificacao,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
}

export async function CreateItemVerificacaoByLocal(
  id_cliente,
  id_item,
  id_local
) {
  return await api
    .post("api/item/verificacao/create/local", {
      id_cliente,
      id_item,
      id_local,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
}

export async function FindItemVerificacaoByCliente(id_cliente) {
  return await api
    .post("api/item/verificacao/find/cliente", {
      id_cliente: localStorage.getItem("id_cliente"),
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
}

export async function FindItemByLocal(id_cliente, id_local) {
  return await api
    .post("api/item/verificacao/find/local", { id_cliente, id_local })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
}
