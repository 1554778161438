import styled from "styled-components";

export const Container = styled.ul`
  margin: 64px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));

  @media(max-width: 720px){
    &{
      flex-direction: row !important;
      grid-template-columns: repeat(1, 1fr) !important;
    }
  }

  a {
    height: 15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    text-align: center;
    width: 100% !important;
    border-radius: 8px;
    color: var(--text-body) !important;
    &:hover{
      box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.25);
    }
  }
`
