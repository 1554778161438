import { FaTools } from "react-icons/fa"
import React from "react";
import styles from "./styles.module.scss"

interface info {
  onClick: () => void;
}

export function ButtonOrdemServico({ onClick } : info ){
  return (
    <a
      className={styles.info}
      onClick={() => onClick()}
    >
      <FaTools size="100" />
      <p>Ordem de Serviço</p>
    </a>
  )
};
