import styled from "styled-components";

export const DivLogin = styled.div`
  display: flex;
  flex-direction: column !important;
  justify-content: center !important;
  place-content: center !important;
  align-items: center !important;
  margin: auto;
  max-width: 100% !important;

  form button {
    background-color:var(--pink0);
  }
`
