import styled from "styled-components";

export const Form = styled.form`
  margin: 0 auto !important;
  display: flex;
  justify-content: center;
  flex-direction: row !important;
  align-items: center;

  input {
    width: 80% !important;
    border: none;
    border-radius: 4px;
    padding: 16px;
    height: 3rem;
  }

  button {
    width: 20% !important;
    padding: 16px;
    height: 3rem;
    width: 100%;
    border: none;
    border-radius: 4px;
    color: var(--white);
    background-color: var(--pink0)
  }
`
