import React, { createContext, useState } from "react";

const Context = createContext();

function UserProvider({ children }) {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || []);
  const [token, setToken] = useState(localStorage.getItem('token') || '');

  return (
    <Context.Provider value={{ user, setUser, setToken, token}}>
      {children}
    </Context.Provider>
  );
}

export { Context, UserProvider };
