import React, { useEffect, useState } from "react";

import {ButtonClose} from "../../../styles/Icons/ClosePage";
import DivElement from "../../../styles/Div/Elements";
import Modal from "react-modal";
import api from "../../../services/api";

interface Action {
  isOpen: boolean;
  handleChangeModalIsOpen: () => void;
  id_cliente: string;
};

export function ListaCheckList({isOpen,handleChangeModalIsOpen, id_cliente } : Action) {

  const [ CheckLists, setCheckLists] = useState([]);

  useEffect(() => {
    api.post("checklist/find", { id_cliente, "sn_pendente": "S" })
    .then((res) => {
      setCheckLists(res.data);
    })
  }, [ isOpen ]);

  return (
    <Modal transparent isOpen={isOpen} style={{ overlay: {}, content: {background: "#f0f2f5", borderRadius: 4, position: "fixed",left: "5%",border: 2,width: "90%",height: "80%",alignContent: "center",}}}>
      <ButtonClose action={handleChangeModalIsOpen} />
      <h1>Check List</h1>
      <DivElement>
         {CheckLists.map((check, index) => (
          <div key={index}>
            <p>{check.desc_local}</p>
            <p>Usuário: {check.nm_usuario}</p>
          </div>
        ))}
      </DivElement>
    </Modal>
  )
};
