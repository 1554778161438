import { BrowserRouter, Route, Switch } from "react-router-dom";

import Dashboard from "./pages/dashboard";
import Login from "./pages/login";
import OsbyId from "./pages/os/OsbyId";
import React from "react";

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/login" exact component={Login} />
        <Route path="/dashboard" exact component={Dashboard} />
        <Route path="/os/:id_ordem/:id_cliente" exact component={OsbyId} />
        <Route path="*" exact component={Login} />
      </Switch>
    </BrowserRouter>
  );
}
