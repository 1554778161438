export const ModalSmall = {
  overlay: {
    display: "flex"
  },
  content: {
    background: "#29292e",
    color: "white",
    position: "fixed",
    top: "10%",
    left: "10%",
    border: 2,
    width: "80%",
    height: "80%",
    alignContent: "center"
  }
};

export const ModalFull = {
  overlay: {
    display: "flex"
  },
  content: {
    background: "#29292e",
    color: "white",
    position: "fixed",
    top: "5%",
    left: "5%",
    border: 2,
    width: "90%",
    height: "90%",
    alignContent: "center"
  }
};
