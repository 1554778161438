import React, { useEffect, useState } from "react";

import {ButtonClose} from "../../../styles/Icons/ClosePage";
import DivElement from "../../../styles/Div/Elements";
import Modal from "react-modal";
import api from "../../../services/api";
import { toast } from "react-toastify";

interface Action {
  isOpen: boolean;
  handleChangeModalIsOpen: () => void;
  id_ordem: string;
  id_cliente: string;
  id_usuario: string;

};

export function OrdemServicoAddItem({isOpen,handleChangeModalIsOpen, id_ordem, id_cliente, id_usuario } : Action) {

  const [ itensDisponiveis, setitensDisponiveis ]= useState([]);

  useEffect(() => {
    if(isOpen)
      api.post("item/verificacao/find", { id_cliente })
      .then((res) => {
        setitensDisponiveis(res.data);
      });
  }, [ isOpen ])

  const handleAddItemOS = (data) => {
    api.post("ordem/it/add", {
      id_cliente,
      id_usuario,
      id_ordem,
      "id_item_verificacao": data.id,
      "observacao_it_ordem": "TESTE"
    }).then(()=> {
      handleChangeModalIsOpen();
    }).catch(() => {
      toast.error('oi')
    })
  }

  return (
    <Modal transparent isOpen={isOpen} style={{ overlay: {}, content: {background: "#f0f2f5", borderRadius: 4, position: "fixed",left: "5%",border: 2,width: "90%",height: "80%",alignContent: "center",}}}>
      <ButtonClose action={handleChangeModalIsOpen} />
      <h1>Adicionar item em O.S</h1>
      <DivElement>
        {itensDisponiveis.map((os, index) => (
          <div key={index} onClick={() => handleAddItemOS(os)}>
            <p>{os.desc_item_verificacao}</p>
          </div>
        ))}
      </DivElement>
    </Modal>
  )
};
