import React, { useEffect, useState } from "react";

import { ButtonClose } from "../../../styles/Icons/ClosePage";
import { ButtonVinculoItemSetor } from "../../buttonVinculoItemSetor";
import { CadastroItem } from "../Item";
import { CadastroOrigem } from "../Origem";
import { CadastroSetor } from "../Setor";
import DivElement from "../../../styles/Div/Elements";
import { FiPlus } from "react-icons/fi";
import Modal from "react-modal";
import { ModalSmall } from "../../../styles/modal.css";
import { VinculoItemAoSetor } from "../VinculoItemSetor";
import api from "../../../services/api";

interface Action {
  isOpen: boolean;
  handleChangeModalIsOpen: () => void;
  id_cliente: string;
}

export function OpcoesCadastro({
  isOpen,
  handleChangeModalIsOpen,
  id_cliente,
}: Action) {
  const [modalIsOpenCadastrarSetor, setmodalIsOpenCadastrarSetor] =
    useState(false);
  const [modalIsOpenCadastrarOrigem, setmodalIsOpenCadastrarOrigem] =
    useState(false);
  const [modalIsOpenCadastrarItem, setmodalIsOpenCadastrarItem] =
    useState(false);
  const [modalIsOpenVinculoSetorAoItem, setmodalIsOpenVinculoSetorAoItem] =
    useState(false);

  const handleCloseModal = () => {
    setmodalIsOpenCadastrarSetor(false);
    setmodalIsOpenCadastrarOrigem(false);
    setmodalIsOpenCadastrarItem(false);
    setmodalIsOpenVinculoSetorAoItem(false);
  };

  return (
    <Modal transparent isOpen={isOpen} style={ModalSmall}>
      <ButtonClose action={handleChangeModalIsOpen} />
      <h1>Cadastros</h1>

      <ButtonVinculoItemSetor />
    </Modal>
  );
}
