import styled from "styled-components";

export const ContainerOpcoes = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row !important;

  select {
    min-width: 300px;
  }
`;
