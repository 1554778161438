import React, { useEffect, useState } from "react";

import { ButtonClose } from "../../../styles/Icons/ClosePage";
import DivElement from "../../../styles/Div/Elements";
import { FaTools } from "react-icons/fa";
import Modal from "react-modal";
import { ModalSmall } from "../../../styles/modal.css";
import { OrdemServicoPorID } from "../PorID";
import api from "../../../services/api";

interface Action {
  isOpen: boolean;
  handleChangeModalIsOpen: () => void;
  id_cliente: string;
  id_usuario: string;
}

export function OrdemServicoPorStatus({
  isOpen,
  handleChangeModalIsOpen,
  id_cliente,
  id_usuario,
}: Action) {
  const [ordemServico, setordemServico] = useState([]);
  const [ordemServicoID, setordemServicoID] = useState([]);
  const [modalIsOpenOrdemPorID, setmodalIsOpenOrdemPorID] = useState(false);

  const handleChangeModalOpenOrdemPorID = (data) => {
    setmodalIsOpenOrdemPorID(!modalIsOpenOrdemPorID);
    if (data) {
      localStorage.setItem("desc_local", data.desc_local);
      setordemServicoID(data);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    api
      .post("ordem/find/cliente", { id_cliente, sn_pendente: "S" })
      .then((res) => {
        setordemServico(res.data);
      });
  }, [isOpen, modalIsOpenOrdemPorID]);

  function openOS(data) {
    window.location.href = "os/" + data.id + "/" + data.id_cliente;
  }

  return (
    <Modal transparent isOpen={isOpen} style={ModalSmall}>
      <ButtonClose action={handleChangeModalIsOpen} />
      <h1>Ordens de serviço</h1>
      <DivElement>
        {ordemServico.map((os, index) => (
          <div key={index} onClick={() => openOS(os)}>
            <FaTools size="24" />
            <p>{os.desc_local}</p>
          </div>
        ))}
      </DivElement>

      <OrdemServicoPorID
        isOpen={modalIsOpenOrdemPorID}
        handleChangeModalIsOpen={handleChangeModalOpenOrdemPorID}
        id={ordemServicoID.id}
        id_cliente={id_cliente}
        id_usuario={id_usuario}
      />
    </Modal>
  );
}
