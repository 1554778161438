import 'moment/locale/pt-br';

import * as moment from 'moment';

import { FindOrdemServicoByID, FindOrdemServicoObservacaoByID } from "../../Functions/OrdemServico";
import React, { useEffect, useState } from "react";

import { FormObservacao } from '../../components/formObservacao';
import styles from "./styles.module.scss";
import { useParams } from "react-router-dom";

export default function OsByid() {

  const { id_ordem, id_cliente } = useParams();

  const [ ordemServico, setOrdemServico ] = useState([]);
  const [ ordemServicoObs, setOrdemServicoObs ] = useState([]);
  const [ reload, setreload ] = useState(false);

  useEffect(() => {
    FindOrdemServicoByID(id_cliente, id_ordem).then((res) => {
      if(res.data)setOrdemServico(res.data[0]);
    });
  }, [])

  useEffect(() => {
    FindOrdemServicoObservacaoByID(id_cliente, id_ordem).then((res) => {
      if(res.data)setOrdemServicoObs(res.data);
    })
  }, [reload])

  if(ordemServico) {
    return (
      <div>
        <h2>Item</h2>
        <p>{ordemServico.desc_item_verificacao}</p>
        <p>{ordemServico.nm_usuario}</p>
        <p>{ordemServico.observacao_it_ordem}</p>

        <FormObservacao
          id_cliente={id_cliente}
          nm_usuario={localStorage.getItem('nm_usuario')}
          id={id_ordem}
          handleReload={() => setreload(!reload)}
        />

        <table className={styles.tableObs}>
          <thead>
            <th>Data</th>
            <th>Observação</th>
            <th>Usuário</th>

          </thead>
          {ordemServicoObs.map((observacao) => (
            <tr>
              <td>{moment(observacao.data).format("dddd, DD MMMM  YYYY")}</td>
              <td>{observacao.desc_observacao}</td>
              <td>{observacao.nm_usuario}</td>
            </tr>
          ))}
        </table>
      </div>
    );
  } else {
    return (
      <p>O.S não encontrada...</p>
    )
  }
}
