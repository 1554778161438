import {
  FindItemByLocal,
  FindItemVerificacaoByCliente,
} from "../../Functions/Item";
import React, { useContext, useEffect, useState } from "react";

import { BsFillPlusSquareFill } from "react-icons/bs";
import { Context as ContextUser } from "../../Context/UserContext";
import { CreateItemVerificacaoByLocal } from "../../Functions/Item";
import { LocalFindByOrigem } from "../../Functions/Local";
import Modal from "react-modal";
import { ModalFull } from "../../styles/modal.css";
import { OrigemFindByCliente } from "../../Functions/Origem";
import styles from "./styles.module.scss";

interface info {
  onClick: () => void;
}

export function ButtonVinculoItemSetor({ onClick }: info) {
  const { user } = useContext(ContextUser);
  const [isOpen, setIsOpen] = useState(false);
  const [listaOrigem, setListaOrigem] = useState([]);
  const [listaLocal, setlistaLocal] = useState([]);
  const [itensVerificacao, setItensVerificacao] = useState([]);
  const [itemPorLocal, setitemPorLocal] = useState([]);
  const [id_origem, setId_origem] = useState("");
  const [id_local, setId_local] = useState("");

  useEffect(() => {
    OrigemFindByCliente(user.id_cliente, "S").then((res) => {
      setListaOrigem(res.data);
    });

    FindItemVerificacaoByCliente(user.id_cliente).then((res) => {
      setItensVerificacao(res.data);
    });
  }, [user]);

  useEffect(() => {
    LocalFindByOrigem(user.id_cliente, id_origem).then((res) => {
      setlistaLocal(res.data);
    });
  }, [id_origem, user]);

  useEffect(() => {
    FindItemByLocal(user.id_cliente, id_local).then((res) => {
      setitemPorLocal(res.data);
    });
  }, [id_local, user]);

  return (
    <>
      <BsFillPlusSquareFill
        size="32"
        color="F9004B"
        className={styles.svg}
        title="Criar ordem de serviço"
        onClick={() => setIsOpen(true)}
      />

      <Modal
        isOpen={isOpen}
        style={ModalFull}
        onRequestClose={() => setIsOpen(false)}
      >
        <div className={styles.Container}>
          <select
            className={styles.selectOption}
            onChange={(e) => setId_origem(e.target.value)}
          >
            <option disabled selected>
              {" "}
              Selecione o andar.
            </option>
            {listaOrigem.map((origem) => (
              <option value={origem.id}>{origem.desc_origem}</option>
            ))}
          </select>

          <select
            className={styles.selectOption}
            onChange={(e) => setId_local(e.target.value)}
          >
            <option disabled selected>
              {" "}
              Selecione o local.
            </option>
            {listaLocal.map((origem) => (
              <option value={origem.id} className={styles.color}>
                {origem.desc_local}
              </option>
            ))}
          </select>

          <select
            className={styles.selectOption}
            onChange={(e) =>
              CreateItemVerificacaoByLocal(
                user.id_cliente,
                e.target.value,
                id_local
              )
            }
          >
            <option disabled selected>
              Selecione o item para cadastrá-lo.
            </option>
            {itensVerificacao.map((origem) => (
              <option value={origem.id} className={styles.color}>
                {origem.desc_item_verificacao}
              </option>
            ))}
          </select>
        </div>

        <div>
          {itemPorLocal.map((item) => (
            <p>{item.desc_item_verificacao}</p>
          ))}
        </div>
      </Modal>
    </>
  );
}
