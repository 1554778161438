import React, { Fragment, useContext, useState } from "react";

import { Context as ContextUser } from "../../Context/UserContext";
import { DivLogin } from "./styles";
import api from "../../services/api";
import { toast } from "react-toastify";

export default function App() {
  const { setUser,setToken } = useContext(ContextUser);
  const [nm_usuario, setnm_usuario] = useState("");
  const [senha, setsenha] = useState("");

  async function handleAnexar(e) {
    e.preventDefault();
    api.post("/api/usuarios/autenticar", {
      nm_usuario, senha
    }).then((res) => {
      localStorage.setItem('user', JSON.stringify(res.data.user[0]));
      localStorage.setItem('token', 'Bearer ' +  res.data.token);
      localStorage.setItem('id_cliente', res.data.user[0].id_cliente);
      localStorage.setItem('id_usuario', res.data.user[0].id);
      localStorage.setItem('nm_usuario', res.data.user[0].nm_usuario);

      setUser(res.data.user[0]);
      setToken('Bearer ' + res.data.token)
       window.location.href = "/dashboard";
    }).catch(() => {
      toast.error('Erro ao logar...', {
        position: 'top-center',
        autoClose: 1500
      });
    })
  }

  return (
    <Fragment>
      <DivLogin>
        <form onSubmit={handleAnexar}>
          <h1>Login</h1>
          <input placeholder="Usuário" onChange={(e) => setnm_usuario(e.target.value)}/>
          <input type="password" placeholder="Senha " onChange={(e) => setsenha(e.target.value)}/>
          <button type="submit">Entrar</button>
        </form>
      </DivLogin>
    </Fragment>
  );
}
