import api from "../services/api";

export async function OrigemFindByCliente(id_cliente, sn_ativo) {
  return await api
    .post("/origem/find", { id_cliente,sn_ativo })
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}
