import { Container } from "./styles";
import { HeaderDefault } from "../../components/Header/index";
import React from "react";

export default class Post extends React.Component {
  render () {
    return (
      <Container>
        <HeaderDefault />
        {this.props.children}
      </Container>
    )
  }
}
