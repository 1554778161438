import "./styles.css";

import React, { Fragment, useContext, useState } from "react";

import { ButtonOrdemServico } from "../../components/buttonOrdemServico";
import { Container } from "./styles";
import { Context as ContextUser } from "../../Context/UserContext";
import { OpcoesCadastro } from "../../components/Cadastros/OpcoesCadastro";
import { OrdemServicoPorStatus } from "../../components/OrdemServico/PorStatus";

export default function App() {
  const { user } = useContext(ContextUser);
  const [modalIsOpenOrcamentos, setmodalIsOpenOrcamentos] = useState(false);
  const [modalIsOpenOpcoesCadastro, setmodalIsOpenOpcoesCadastro] =
    useState(false);

  const handleModalChangeModalOrcamentos = () => {
    setmodalIsOpenOrcamentos(!modalIsOpenOrcamentos);
  };

  const handleChangeModalIsOpenOpcoesCadastro = () => {
    setmodalIsOpenOpcoesCadastro(!modalIsOpenOpcoesCadastro);
  };

  return (
    <Fragment>
      <Container>
        <ButtonOrdemServico
          onClick={() => handleModalChangeModalOrcamentos()}
        />
      </Container>

      <OrdemServicoPorStatus
        isOpen={modalIsOpenOrcamentos}
        handleChangeModalIsOpen={handleModalChangeModalOrcamentos}
        id_cliente={user.id_cliente}
      />

      <OpcoesCadastro
        isOpen={modalIsOpenOpcoesCadastro}
        handleChangeModalIsOpen={handleChangeModalIsOpenOpcoesCadastro}
        id_cliente={user.id_cliente}
      />
    </Fragment>
  );
}
