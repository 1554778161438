import styled from "styled-components";

export const Container = styled.ul`
  padding: 32px 8px;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(5, 1fr) !important;
  grid-gap: 16px !important;
  color: var(--text-body) !important;
  overflow: hidden !important;

  @media(max-width: 720px){
    &{
      text-align: center !important;
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }

  div {
    padding: 16px;
    text-align: center;
    border-radius: 4px;
    background-color: var(--gray-700);
    transition: filter 0.8s;
  }

  div:hover {
    cursor: pointer;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
    background-color: var(--white);
    filter: brightness(0.9) !important;
  }

  select {
    font: bold;
    width: 100%;
    height: 4rem;
    padding: 8px;
    border-radius: 8px;
    color: var(--pink0);
  }
`;
