import api from "../services/api";

export async function FindOrdemServicoByID(id_cliente, id_ordem) {
  return await api
    .post("ordem/it/find/ordem", { id_cliente,id_ordem })
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}

export async function FindOrdemServicoObservacaoByID(id_cliente, id_ordem_servico) {
  return await api
    .post("ordem/find/obs", { id_cliente,id_ordem_servico })
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}
